@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply w-full rounded-md border-0 border-b border-gray-300 px-3 py-3 text-xs placeholder-gray-400 focus:z-10 focus:outline-none dark:bg-night-700 dark:text-night-400 dark:placeholder-night-200;
  }

  [type='checkbox'],
  [type='radio'] {
    @apply h-4 w-4 rounded border-gray-300 bg-gray-100 text-green-600 focus:ring-2 focus:ring-green-500 dark:border-night-600 dark:bg-night-700 dark:ring-offset-night-800 dark:focus:ring-green-600;
  }
}

@layer components {
  .dropdown-content {
    @apply pointer-events-none scale-95 opacity-0 duration-100 ease-in;
  }

  .dropdown:hover>.dropdown-content {
    @apply pointer-events-auto block scale-100 animate-fade-in-up opacity-100 duration-200;
  }
}

/** Scroll bar **/
@supports selector(::-webkit-scrollbar) {
  .supports-scrollbars\: pr-2 {
    padding-right: 0.5rem;
  }
}

/** Plugins **/
@import './assets/styles/apexchart.scss';


* {
  font-family: 'Manrope', sans-serif !important;
}

.vertical-center {
  align-items: center !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: white !important;
}


p {
  margin: 0 !important;
}

.dataNotFound {
  width: 100%;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;

  h2 {
    font-size: 18px;
    font-weight: 700;
    width: 400px;
    text-align: center;
    margin-bottom: 50px;
  }


  img {
    width: 200px;
  }

}

.cancelClicked {
  height: 28px;
  width: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
}
